<template>
    <div class="authorization-content">
        <el-card class="authorization-card">
            <div style="width: 100%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                <h3>请授权</h3>
            </div>
            <div style="width: 100%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                <h4>{{serialNumber}}</h4>
            </div>

            <div style="width: 100%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                <el-image :src="axios.defaults.baseURL+'/authorization/getSerialNumberQrCode'"></el-image>
            </div>

            <el-input class="password" type="textarea" placeholder="授权码" v-model="authorization"/>
            <div class="requestLogin-content" @click="verifyAuthorization">
                <el-button class="login" type="primary" :loading="isLoading">授权</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isLoading: false,
                serialNumber: '',
                authorization: ''
            }
        },
        mounted() {
            this.getSerialNumberQrCode()
        },
        methods: {
            getSerialNumberQrCode() {
                setTimeout(function () {
                    axios.get('/authorization/getSerialNumber')
                        .then(res => {
                            if (res.data.code === 1) {
                                this.serialNumber = res.data.data
                            }
                        })
                }.bind(this))
            },
            verifyAuthorization() {
                setTimeout(function () {
                    axios.get('/authorization/setAuthorizationCode?authorizationCode=' + this.authorization)
                        .then((res) => {
                            if (res.data.code === 1) {
                                this.$message.success(res.data.msg);
                                this.$router.replace('/')
                            } else {
                                this.$message.error(res.data.msg);
                            }
                        })
                }.bind(this))
            }
        }

    }
</script>

<style>
    .authorization-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: url(../../assets/bg.webp);
        background-size: 100% 100%;
    }

    .authorization-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 400px;
        background: #ffffff;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 40px;
        border-radius: 10px;
    }

    .password {
        margin-top: 20px;
    }

    .login {
        width: 100%;
    }


    .requestLogin-content {
        margin-top: 20px;
        width: 100%;
    }
</style>
